.playlist-banner-wrapper {
  &:after {
    display: block;
    position: relative;
    background-image: -webkit-linear-gradient(
      top,
      transparent 0%,
      #000000 100%
    );
    background-image: linear-gradient(to bottom, transparent 0%, #000000 100%);
    margin-top: -150px;
    height: 150px;
    width: 100%;
    content: "";
    top: 100%;
    pointer-events: none;
  }
}
.playlist-banner {
  overflow: hidden;

  img {
    width: 100%;
  }
}

.playlist-thumbnail {
  margin-right: 5px;
  img {
    width: 100%;
  }
}

.playlist-title {
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: calc(50% - 10px);
  left: 0;
  font-size: 16px;
  font-family: "Proxima Nova Lt";
  font-weight: 600;
  color: #ffffff;
  text-align: center;
  text-shadow: 0 0 2px #000000;
}

.table {
  &.playlist-table {
    background-color: transparent;
    color: #ffffff;

    th {
      font-size: 12px;
      color: #808080;
      border-color: #212121;

      &.play {
        width: 130px;
      }

      &.locker,
      &.download,
      &.bpm {
        width: 130px;
        text-align: center;
      }

      &.locker,
      &.download {
        &.disabled {
          background-color: #242525;
        }
      }

      &.track {
        width: 400px;
        max-width: 100%;
      }

      @media screen and (max-width: 1440px) {
        &.track {
          width: 250px;
          max-width: 100%;
        }
      }
    }

    tr {
      cursor: pointer;
    }

    td {
      padding-top: 0;
      padding-bottom: 0;
      border-color: #212121;
      vertical-align: middle;

      &.locker,
      &.download {
        &.disabled {
          background-color: #242525;
        }
      }

      .open-icon {
        float: left;
        margin-left: 30px;
        margin-top: 8px;
        transition-duration: 0.2s;
        transition-property: transform, color;
      }

      .play-icon {
        float: left;
        margin-top: 6px;
        margin-bottom: 6px;
        opacity: 0.6;

        img {
          display: block;
          width: 30px;
        }
      }

      &.locker,
      &.download,
      &.bpm {
        text-align: center;
      }

      &.locker {
        cursor: pointer;
        color: #808080;
      }

      &.download {
        a {
          color: #808080;
        }
      }
    }

    tr {
      &.is-expanded {
        td {
          border: 0;
          padding-bottom: 0;
        }

        .play-icon {
          opacity: 1;
        }
      }

      &.expand {
        .tags-cell {
          font-size: 12px;
          font-family: "Proxima Nova Rg";
          padding-top: 0;

          .tag-title {
            width: 100%;
            clear: both;
            margin-bottom: 5px;
          }

          .tag-name {
            &,
            &:focus {
              color: #808080;
              display: inline-block;
              padding-left: 4px;
              padding-right: 4px;
              margin-right: 5px;
              margin-bottom: 20px;
              background-color: transparent;
              cursor: pointer;
              border: none;
              outline: none;
            }
          }
        }

        .license {
          font-size: 12px;
          font-family: "Proxima Nova Rg";
          color: #7e7e7e;
          margin-top: 5px;
          margin-bottom: 10px;
          padding-left: 4px;
        }
      }
      .proImg-class {
        width: 30px;
        margin-left: 12px;
      }
      .proImg-class-two {
        width: 30px;
      }
    }
  }
}

.proImg-class {
  width: 30px;
  vertical-align: middle;
  margin-top: -5px;
}
.proImg-class-two {
  width: 30px;
}
