@import '../../variables';

html {
  background-color: #1b1b1b !important;
}

.logo {
  margin-top: calc((100px - 47px) / 2);
  max-width: 100%;
  height: 47px;
}

.logo-container {
  width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.search-container {

}

.menu {
  z-index: 10;
  position: absolute;
  top: 30px;
  right: -24px;

  &.active {
    .ms-navbar-menu {
      height: 50px;
    }
  }

  .ms-navbar-menu {
    position: absolute;
    height: 0;
    width: 200px;
    overflow: hidden;
    background-color: #000000;
    border: 1px solid #1b1b1b;
    right: 17px;
    top: 40px;
    transition: height 100ms linear;

    a {
      display: block;
      padding: 10px;
      color: #ffffff;
    }
  }
}

.avatar-container {
  width: 239px;
  padding-left: 15px;
}

@media screen and (max-width: 1440px) {
  .avatar-container {
    width: 239px;
  }
}

.header {
  height: $header-height;
  background-color: $background-primary;
  color: #1f1313;
  margin-bottom: 5px;
  > .container {
    margin-left: 25px !important;
    margin-right: 25px !important;
  }

  .avatar-wrapper {
    margin-top: calc((#{$header-height} - 12px) / 2);
  }

  .user-name {
    display: block;
    color: #ffffff;
    font-size: 14px;
    margin-top: calc((#{$header-height} - 4px) / 2);
    padding-left: 0;
    margin-left: -3px;
  }

  .search-field {
    margin-top: 20px;
    margin-right: -10px;
    float: right;

    .icon {
      color: #7b7b7b;
      font-size: 18px;
    }

    input {
      background-color: #363636;
      width: 100%;
      height: 40px;
      padding-top: 0;
      padding-bottom: 0;
      max-width: 420px;
      border: 0;
      border-radius: 0;
      padding-left: 1.5em !important;
      color: #ffffff;
      font-size: 30px;
      font-family: 'Proxima Nova Lt';
      &:focus {
        outline: none;
        box-shadow: none;
      }
      &::placeholder {
        color: #7b7b7b;
        font-size: 30px;
        font-weight: 300;
        font-family: 'Proxima Nova Lt';
      }
    }
  }
}
