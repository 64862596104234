.category-main-banner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 417px;
  background-size: contain;
  background-position: top center;

  &::after {
    display: block;
    position: relative;
    background-image: linear-gradient(to bottom, transparent 0%, #000000 100%);
    margin-top: -150px;
    height: 150px;
    width: 100%;
    content: "";
    top: 100%;
  }
}
.play-all-container{
  display: flex;
}
.play-all-button{
  color: black;
  background: #30D057 0% 0% no-repeat padding-box;
  font: Proxima Nova;
  font-size: 12px;
  font-weight: 800;
  padding: 10px 25px;
  border-radius: 15px;
}
.download-all-button{
  
}

.category-main-banner-content {
  position: relative;

  .category-name,
  .category-title,
  .category-last-updated,
  .category-description {
    display: block;
    clear: both;
    color: #ffffff;
  }

  .category-name {
    font-size: 16px;
    font-family: "Proxima Nova Rg";
    font-weight: bold;
  }

  .category-title {
    font-size: 60px;
    font-weight: 300;
    font-family: "Proxima Nova Lt";
  }

  .category-last-updated {
    margin-left: 10px;

    strong {
      color: #ffffff;
      font-family: "Proxima Nova Rg";
      font-weight: bold;
    }
  }

  .category-description {
    margin-left: 10px;
    margin-top: 0;
    width: 650px;
    max-width: 100%;
  }
}

@media screen and (max-width: 1850px) {
  .category-main-banner-content {
    .category-title {
      font-size: 60px;
    }

    .category-last-updated {
      margin-left: 5px;
    }

    .category-description {
      margin-left: 5px;
      font-size: 12px;
    }
  }
  @media screen and (max-width: 1440px) {
    .category-main-banner-content .category-title {
      font-size: 50px;
      margin-top: 40px;
    }
  }

  @media screen and (max-width: 1024px) {
    .category-main-banner-content .category-title {
      font-size: 40px;
      margin-top: 20px;
    }
  }

  @media screen and (max-width: 575px) {
    .category-main-banner-content .category-title {
      font-size: 30px;
      margin-top: 18px;
    }
  }
}
