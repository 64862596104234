@import '../../variables';

.logo {
  margin-top: calc((100px - 47px) / 2);
  max-width: 100%;
  height: 47px;
}

@media screen and (max-width: 540px){
  .logo {
  margin-top: calc((100px - 47px) / 2);
  max-width: 100%;
  height: auto;
}
.logo-container {
  max-width: 120px;
}

}

.logo-container {
  width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.search-container {

}

.avatar-container {
  width: 239px;
  padding-left: 15px;
}

@media screen and (max-width: 1440px) {
  .avatar-container {
    width: 239px;
  }
}

.header {
  height: $header-height;
  background-color: $background-primary;
  color: #1f1313;
  margin-bottom: 5px;

  > .container {
    margin-left: 25px !important;
    margin-right: 25px !important;
  }

  .avatar-wrapper {
    margin-top: calc((#{$header-height} - 12px) / 2);
  }

  .user-name {
    display: block;
    color: #ffffff;
    font-size: 14px;
    margin-top: calc((#{$header-height} - 4px) / 2);
    padding-left: 0;
    margin-left: -3px;
  }

  .search-field {
    margin-top: 20px;
    margin-right: -10px;
    float: right;

    .icon {
      color: #7b7b7b;
      font-size: 18px;
    }

    input {
      background-color: #363636;
      width: 100%;
      height: 40px;
      padding-top: 0;
      padding-bottom: 0;
      max-width: 420px;
      border: 0;
      border-radius: 0;
      padding-left: 1.5em !important;
      color: #ffffff;
      font-size: 30px;
      font-family: 'Proxima Nova Lt';

      &::placeholder {
        color: #7b7b7b;
        font-size: 30px;
        font-weight: 300;
        font-family: 'Proxima Nova Lt';
      }
    }
  }
}

.request-access-container
{
  width: 200px;
  text-align: center;
  vertical-align: middle;

  a {
    display: block;
    background-color: transparent!important;
    border: 1px solid #fff!important;
    color: #fff;
    cursor: pointer;
    position: relative;
    transition: all .3s cubic-bezier(.42,0,.58,1);
    padding: 10px;
    margin-top: 25px;
    &:hover {
      border-color: #fff!important;
      color: #000!important;
      background-color: #fff!important;
    }
  }

}

@media screen and (max-width: 540px) {
.header {
    height: 63px;
}

}

