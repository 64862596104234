@import "variables";
@import "../node_modules/bulma/bulma";
@import "../node_modules/bulma-extensions/bulma-tooltip/dist/css/bulma-tooltip.min.css";
@import "../node_modules/react-alice-carousel/lib/alice-carousel.css";
$pn-font-path: "/fonts";

@font-face {
  font-family: "Proxima Nova Lt";
  src: url("#{$pn-font-path}/ProximaNova-LightIt.eot");
  src: local("Proxima Nova Light Italic"), local("ProximaNova-LightIt"),
    url("#{$pn-font-path}/ProximaNova-LightIt.eot?#iefix")
      format("embedded-opentype"),
    url("#{$pn-font-path}/ProximaNova-LightIt.woff") format("woff"),
    url("#{$pn-font-path}/ProximaNova-LightIt.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Proxima Nova";
  src: url("#{$pn-font-path}/ProximaNova-Regular.eot");
  src: local("Proxima Nova Regular"), local("ProximaNova-Regular"),
    url("#{$pn-font-path}/ProximaNova-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("#{$pn-font-path}/ProximaNova-Regular.woff") format("woff"),
    url("#{$pn-font-path}/ProximaNova-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Proxima Nova Lt";
  src: url("#{$pn-font-path}/ProximaNova-Semibold.eot");
  src: local("Proxima Nova Semibold"), local("ProximaNova-Semibold"),
    url("#{$pn-font-path}/ProximaNova-Semibold.eot?#iefix")
      format("embedded-opentype"),
    url("#{$pn-font-path}/ProximaNova-Semibold.woff") format("woff"),
    url("#{$pn-font-path}/ProximaNova-Semibold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Proxima Nova Lt";
  src: url("#{$pn-font-path}/ProximaNova-SemiboldIt.eot");
  src: local("Proxima Nova Semibold Italic"), local("ProximaNova-SemiboldIt"),
    url("#{$pn-font-path}/ProximaNova-SemiboldIt.eot?#iefix")
      format("embedded-opentype"),
    url("#{$pn-font-path}/ProximaNova-SemiboldIt.woff") format("woff"),
    url("#{$pn-font-path}/ProximaNova-SemiboldIt.ttf") format("truetype");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "Proxima Nova Rg";
  src: url("#{$pn-font-path}/ProximaNova-RegularIt.eot");
  src: local("Proxima Nova Regular Italic"), local("ProximaNova-RegularIt"),
    url("#{$pn-font-path}/ProximaNova-RegularIt.eot?#iefix")
      format("embedded-opentype"),
    url("#{$pn-font-path}/ProximaNova-RegularIt.woff") format("woff"),
    url("#{$pn-font-path}/ProximaNova-RegularIt.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Proxima Nova Rg";
  src: url("#{$pn-font-path}/ProximaNova-Bold.eot");
  src: local("Proxima Nova Bold"), local("ProximaNova-Bold"),
    url("#{$pn-font-path}/ProximaNova-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("#{$pn-font-path}/ProximaNova-Bold.woff") format("woff"),
    url("#{$pn-font-path}/ProximaNova-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Proxima Nova Lt";
  src: url("#{$pn-font-path}/ProximaNova-Light.eot");
  src: local("Proxima Nova Light"), local("ProximaNova-Light"),
    url("#{$pn-font-path}/ProximaNova-Light.eot?#iefix")
      format("embedded-opentype"),
    url("#{$pn-font-path}/ProximaNova-Light.woff") format("woff"),
    url("#{$pn-font-path}/ProximaNova-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

.main-content {
  overflow-x: hidden;
  padding: 0;
}

.main-content::-webkit-scrollbar {
  width: 8px;
}

* html body {
  scrollbar-face-color: #889b9f;
  scrollbar-shadow-color: #3d5054;
  scrollbar-highlight-color: #c3d6da;
  scrollbar-3dlight-color: #3d5054;
  scrollbar-darkshadow-color: #85989c;
  scrollbar-track-color: #95a6aa;
  scrollbar-arrow-color: #ffd6da;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #242424;
  box-shadow: inset 0 0 5px #353535;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #3f3f3f;
}

.ms-navbar-burger {
  display: block;
  height: 30px;
  width: 30px;
  margin-right: 20px;
  img {
    width: 100%;
    height: 100%;
  }
}
