@import '../../variables';

nav {
  padding-left: 15px;
  padding-top: 13px;

  .sidebar-list {
    .disabled {
      background-color: #242525;
    }
  }
}
