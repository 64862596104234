@import "../variables";

body {
  background-color: #000000;
}

.main {
  height: calc(100vh - #{$header-height} - #{$footer-height} - 10px);
  overflow: hidden;
  > .container {
    margin-left: 0 !important;
    margin-right: 0 !important;
    min-height: 100%;
    > .columns {
      min-height: calc(100vh - #{$header-height} - #{$footer-height});
    }
  }
}

.sidebar-column {
  background-color: $background-primary;
  font-size: 21px;
  font-family: "Proxima Nova Lt";
  font-weight: 300;
  line-height: 2;

  a {
    color: #ffffff;
  }
}

.activity-column {
  background-color: $background-primary;
}

.sidebar-column,
.activity-column {
  min-height: 100%;
  width: 222px;
  flex: none !important;
  transition: width 300ms, font-size 300ms;
}

.activity-column {
  width: 266px;
}

@media screen and (max-width: 1440px) {
  .sidebar-column {
    font-size: 21px;
    transition: width 300ms, font-size 300ms;
  }
}

.audioplayer-section {
  height: $footer-height;
  overflow: hidden;
  margin-top: 5px;
  background-color: $background-primary;
}

.alice-carousel__dots {
  margin-top: 0 !important;

  .alice-carousel__dots-item {
    width: 92px !important;
    height: 4px !important;
    cursor: pointer;
    border-radius: 0 !important;
    background-color: #808080 !important;

    &.__active {
      background-color: #ffffff !important;
    }
  }
}

.main-content {
  height: calc(100vh - #{$header-height} - #{$footer-height});
  overflow: scroll;
  position: relative;
}

@media screen and (max-width: 540px){
.main {
    height: calc(100vh - 160px);
}
}