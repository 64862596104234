.preview-page {
  .main{
    max-width: 1080px;
    margin: 0 auto;
  }
  .main-content{
    display: block;
  }
  .activity-column{
    width: 0;
  }
  .sidebar-column{
    display: none;
  }
  .activity-column{
    display: none;
  }
  .category-main-banner-content{
    display: flex;
    flex-direction: column;
    justify-content: center;
}
  .table {
    th{
      line-height: vertical;
      vertical-align: middle;
    }
    &.playlist-table {
      background-color: transparent;
      color: #ffffff;
      tr {
        min-height: 42px !important;
        }
      th {
        &.play {
          width: 155px;
          padding-left: 46px;
        }
        
        &.locker,
        &.download,
        &.bpm {
          width: 130px;
          text-align: center;
        }

        &.bpm {
          width: 230px;
        }
        @media screen and (max-width: 540px) {
          &.disabled {
            display: none;
          }
        }
        &.locker,
        &.download {
          &.disabled {
            background-color: #242525;
          }
        }

        &.spacer {
          width: 40px;
        }

        &.track {
          width: 500px;
          max-width: 100%;
        }

        @media screen and (max-width: 1440px) {
          &.track {
            width: 250px;
            max-width: 100%;
          }
        }

        @media screen and (max-width: 540px) {
          &.locker {
            display: none;
          }
          &.bpm {
            display: none;
          }
          &.spacer {
            display: none;
          }
          &.download {
            display: none;
          }
          &.locker {
            display: none;
          }
          &.disabled {
            display: none;
          }
        }
      }
      
      td {
        vertical-align: middle;
        line-height: 1.5;
        @media screen and (min-width: 540px) {
          &.play {
            padding-left: 47px;
          }
          &.name{
            padding: 16px 0 16px 0;
          }
        }
        
        @media screen and (max-width: 540px) {
          &.download &.locker &.spacer &.disabled {
            display: none;
          }
          &.play {
            padding-left: 32px;
          }
          .open-icon {
            float: right;
            margin-left: 0px;
          }
          &.download {
            display: none;
          }
          &.locker {
            display: none;
          }
          &.disabled {
            display: none;
          }
        }

        &.locker,
        &.download {
          &.disabled {
            background-color: #242525;
          }
        }

        &.locker {
          cursor: pointer;
          color: #808080;
        }

        &.download {
          a {
            color: #808080;
          }
        }

        @media screen and (max-width: 540px) {
          &.disabled {
            display: none;
          }
          &.bpm {
            display: none;
          }
          &.spacer {
            display: none;
          }
          &.name {
            width: 45%;
          }
          &.expand-spacer {
            display: none;
          }
          &.tags-cell {
            text-align: center;
          }
        }
      }
    }
  }
}



@media screen and (max-width: 540px) {
    .playlist-table {
      margin-bottom: 280px;
      z-index: 9998 !important;
    }
    .audioplayer-section {
      height: 30vh;
      margin-top: -95px;
      z-index: 9998 !important;
    }
    .table tbody {
    background: inital;
    font-size: 11px;
  }
  .sidebar-column {
      display: none !important;
      width: 0;
      background-color: transparent;
    }
.table.playlist-table th {
    font-size: 8px;
    color: grey; 
    border-color: #212121;
    letter-spacing: 2px;
}
.preview-page .table.playlist-table th.play {
    width: auto;
}
.preview-page .table.playlist-table td .open-icon {
    float: right;
    margin-left: 0;
    font-size: 18px;
}
  .preview-page .table.playlist-table td .open-icon {
    float: right;
    margin-left: 0;
    font-size: 18px;
}

.category-main-banner-content .category-title {
    font-size: 30px;
    margin-top: 0;
    // position: absolute;
    white-space: nowrap;
    // bottom: 50%;
    width: 100%;
    white-space: nowrap;
    align-self: flex-end;
}
.category-main-banner-content .category-description {
    margin-left: 0;
    font-size: 12px;
    white-space: nowrap;
    align-self: start;
}
.preview-page .table.playlist-table {
    margin-top: 0;
    background-color: initial;
    color: #fff;
}
.category-main-banner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 417px;
    background-size: cover;
    background-position: left;
}
.category-main-banner-content {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding-left: 0 !important;
    margin-left: 25% !important;
}
.audioplayer-section {
    height: 25vh;
   }
}



