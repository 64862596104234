@import "../../variables";

.audio-player-wrapper {
  height: 100%;
  color: #ffffff;

  .container {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .audio-player {
    height: $audioplayer-height;
  }

  .song-title,
  .song-producer,
  .song-bpm {
    display: block;
    clear: both;
  }
  

  @media screen and (max-width: 540px) {
    margin-left: 25px;
  }
  .song-title {
    font-family: "Proxima Nova Lt";
    font-weight: 600;
    font-size: 16px;
    color: #ffffff;
    margin-top: 20px;
  }

  .song-producer,
  .song-bpm {
    font-size: 12px;
    color: #7f7f7f;
  }

  .controls {
    margin-bottom: 0;

    .column {
      width: 100%;
      max-width: 300px;
      margin: 10px auto 0;
      text-align: center;

      * {
        margin: 0 30px;
        font-size: 30px;
        line-height: 50px;
        vertical-align: middle;
      }

      @media screen and (max-width: 1440px) {
        * {
          margin: 0 20px;
        }
      }
    }

    .play {
      width: 30px;
      transition: width 300ms, height 300ms;
    }
  }

  .current-time,
  .total-time {
    text-align: center;
    font-size: 14px;
    line-height: 0.5;
    padding-top: 21px;
  }
}

/**
* Rangeslider - volume
*/
.volume-control-wrapper {
  margin-top: 10px !important;

  * {
    user-select: none;
  }

  .volume-slider {
    max-width: 170px;
  }
  .volume-container {
    justify-content: center;
    flex-direction: column;
    display: flex;

    .fas {
      font-size: 20px;
    }
  }
  
  .rangeslider {
    margin: 22px 0;
    position: relative;
    background: #484848;
    -ms-touch-action: none;
    touch-action: none;
  }



  .rangeslider,
  .rangeslider .rangeslider__fill {
    display: block;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.4);
  }
  .rangeslider .rangeslider__handle {
    background: #fff;
    border: 1px solid #ccc;
    cursor: pointer;
    display: inline-block;
    position: absolute;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4), 0 -1px 3px rgba(0, 0, 0, 0.4);
    &:focus {
      outline: none;
    }
  }
  .rangeslider .rangeslider__handle .rangeslider__active {
    opacity: 1;
  }
  .rangeslider .rangeslider__handle-tooltip {
    width: 40px;
    height: 40px;
    text-align: center;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.8);
    font-weight: normal;
    font-size: 14px;
    transition: all 100ms ease-in;
    border-radius: 4px;
    display: inline-block;
    color: white;
    left: 50%;
    transform: translate3d(-50%, 0, 0);
  }
  .rangeslider .rangeslider__handle-tooltip span {
    margin-top: 12px;
    display: inline-block;
    line-height: 100%;
  }
  .rangeslider .rangeslider__handle-tooltip:after {
    content: " ";
    position: absolute;
    width: 0;
    height: 0;
  }
  /**
  * Rangeslider - Horizontal slider
  */
  .rangeslider-horizontal {
    height: 3px;
    border-radius: 10px;
  }
  .rangeslider-horizontal .rangeslider__fill {
    height: 100%;
    background-color: #ffffff;
    border-radius: 0;
    top: 0;
  }
  .rangeslider-horizontal .rangeslider__handle {
    width: 10px;
    height: 10px;
    top: 50%;
    border-radius: 30px;
    transform: translate3d(-50%, -50%, 0);
  }

  .rangeslider-horizontal .rangeslider__handle-tooltip {
    top: -55px;
  }
  .rangeslider-horizontal .rangeslider__handle-tooltip:after {
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid rgba(0, 0, 0, 0.8);
    left: 50%;
    bottom: -8px;
    transform: translate3d(-50%, 0, 0);
  }

  /**
  * Rangeslider - Labels
  */
  .rangeslider__labels {
    position: relative;
  }
  .rangeslider-vertical .rangeslider__labels {
    position: relative;
    list-style-type: none;
    margin: 0 0 0 24px;
    padding: 0;
    text-align: left;
    width: 250px;
    height: 100%;
    left: 10px;
  }
  .rangeslider-vertical .rangeslider__labels .rangeslider__label-item {
    position: absolute;
    transform: translate3d(0, -50%, 0);
  }
  .rangeslider-vertical .rangeslider__labels .rangeslider__label-item::before {
    content: "";
    width: 10px;
    height: 2px;
    background: black;
    position: absolute;
    left: -14px;
    top: 50%;
    transform: translateY(-50%);
    z-index: -1;
  }
  .rangeslider__labels .rangeslider__label-item {
    position: absolute;
    font-size: 14px;
    cursor: pointer;
    display: inline-block;
    top: 10px;
    transform: translate3d(-50%, 0, 0);
  }
}

/**
* Rangeslider - cursor
*/
.cursor-slider {
  * {
    user-select: none;
  }

  .rangeslider {
    margin: 11px 0;
    position: relative;
    background: #484848;
    -ms-touch-action: none;
    touch-action: none;
  }
  .rangeslider,
  .rangeslider .rangeslider__fill {
    display: block;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.4);
  }
  .rangeslider .rangeslider__handle {
    background: #fff;
    border: 1px solid #ccc;
    cursor: pointer;
    display: inline-block;
    position: absolute;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4), 0 -1px 3px rgba(0, 0, 0, 0.4);
    &:focus {
      outline: none;
    }
  }
  .rangeslider .rangeslider__handle .rangeslider__active {
    opacity: 1;
  }
  .rangeslider .rangeslider__handle-tooltip {
    width: 40px;
    height: 40px;
    text-align: center;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.8);
    font-weight: normal;
    font-size: 14px;
    transition: all 100ms ease-in;
    border-radius: 4px;
    display: inline-block;
    color: white;
    left: 50%;
    transform: translate3d(-50%, 0, 0);
  }
  .rangeslider .rangeslider__handle-tooltip span {
    margin-top: 12px;
    display: inline-block;
    line-height: 100%;
  }
  .rangeslider .rangeslider__handle-tooltip:after {
    content: " ";
    position: absolute;
    width: 0;
    height: 0;
  }
  /**
  * Rangeslider - Horizontal slider
  */
  .rangeslider-horizontal {
    height: 25px;
    border: 10px solid #1b1b1b;
  }
  .rangeslider-horizontal .rangeslider__fill {
    height: 100%;
    background-color: #ffffff;
    border-radius: 0;
    top: 0;
  }
  .rangeslider-horizontal .rangeslider__handle {
    width: 10px;
    height: 10px;
    border-radius: 30px;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
  }

  .rangeslider-horizontal .rangeslider__handle-tooltip {
    top: -55px;
  }
  .rangeslider-horizontal .rangeslider__handle-tooltip:after {
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid rgba(0, 0, 0, 0.8);
    left: 50%;
    bottom: -8px;
    transform: translate3d(-50%, 0, 0);
  }

  /**
  * Rangeslider - Labels
  */
  .rangeslider__labels {
    position: relative;
  }
  .rangeslider-vertical .rangeslider__labels {
    position: relative;
    list-style-type: none;
    margin: 0 0 0 24px;
    padding: 0;
    text-align: left;
    width: 250px;
    height: 100%;
    left: 10px;
  }
  .rangeslider-vertical .rangeslider__labels .rangeslider__label-item {
    position: absolute;
    transform: translate3d(0, -50%, 0);
  }
  .rangeslider-vertical .rangeslider__labels .rangeslider__label-item::before {
    content: "";
    width: 10px;
    height: 2px;
    background: black;
    position: absolute;
    left: -14px;
    top: 50%;
    transform: translateY(-50%);
    z-index: -1;
  }
  .rangeslider__labels .rangeslider__label-item {
    position: absolute;
    font-size: 14px;
    cursor: pointer;
    display: inline-block;
    top: 10px;
    transform: translate3d(-50%, 0, 0);
  }
}

.producer {
  white-space: nowrap;
}

.mobile-player-play {
  display: flex;
  flex-direction: row;
  align-content: center;
  text-align: center;
  justify-content: space-around;
}

@media screen and (max-width: 540px) {
  .playlist-table .preview-page {
    margin-top: 10px;
  }
  .mobile-player-play{
    flex-direction: column;  
margin-left: 19px;

  }
  .controls{
        margin-bottom:6px;
    }
    .fifths-mobile{
     background: #1c1c1c;
    }

    .extra-space-color{
      background: #1c1c1c;
    }
    
}

.audio-pro {
  margin-left: 10px;
}


@media screen and (max-width: 540px){
.audio-player-wrapper {
    margin-left: 0;
    overflow: visible;
}
.audio-player-wrapper .song-title {
    margin-top:5px;
    flex-wrap: wrap;
}
.song-title {
  width: 95%;
}

}

@media screen and (max-width: 540px){
.audioplayer-section {
    z-index: 9998!important;
    height: 40vh;
    margin-top: 0;
    position: absolute !important;
    bottom: 0;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 20px 0px 40px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    -webkit-box-shadow: 0px -8px 20px -11px rgba(0,0,0,0.4);
    -moz-box-shadow: 0px -8px 20px -11px rgba(0,0,0,0.4);
    box-shadow: 0px -8px 40px -11px rgba(0,0,0,0.9);
    box-shadow: 0px -15px 20px 3px rgba(0,0,0,1);

}
}


