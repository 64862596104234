.featured {
  h1 {
    color: #ffffff;
    font-size: 50px;
    font-family: 'Proxima Nova Lt';
    font-weight: 300;
    text-align: center;
    margin-top: 30px;
    transition: font-size 300ms;
  }

  @media screen and (max-width: 1440px) {
    h1 {
      font-size: 30px;
    }
  }

  .chevron-container-left, .chevron-container-right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    img {
      margin-bottom: 30px;
      height: 42px;
      opacity: 0.7;
      cursor: pointer;
      &:hover {
        opacity: 1;
      }
    }
  }

  .chevron-container-left {
    align-items: flex-end;
  }
}
