.audioplayer-section{
    position: sticky;
}

@media screen and (max-width: 540px) {
    .rangeslider{
      margin-right: 10px;
    }
    .cursor-slider{
      margin-right: 15px;
      margin-left:15px;
      margin-bottom:15px;
      margin-top: -25px;
    
    }
    .is-10{
        margin-bottom:15px;
    }
    body{
        position:fixed;
        margin-bottom:45px;
        overflow: hidden;
        height: 100%;
        width:100%;
    }
    .mobile-time-slider{
        position: fixed;
    width: 100%;
    background: #1c1c1c;
    margin-left: 0px;
    margin-right:0px;
    height: 100px;

    }
    .play-img-column{
        margin-bottom:6px;
    }
  }

  .preview-page .table.playlist-table tr {
    min-height: 42px!important;
    line-height: 1.2;
}
@media screen and (max-width: 540px){
.preview-page .table.playlist-table td.name {
    line-height: 1.5;
}
}
