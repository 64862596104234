.recents-container {
  overflow: hidden;

  h1 {
    font-family: "Proxima Nova Lt";
    color: #ffffff;
    font-weight: 300;
    font-size: 30px;
    transition: font-size 300ms;
  }

  .recent {
    position: relative;
    cursor: pointer;
    background-color: #000000;
    padding: 5px;
    margin: 0 10px 5px 0;

    .song-title {
      display: block;
      font-family: "Proxima Nova Lt";
      font-weight: 600;
      clear: both;
      font-size: 16px;
      color: #ffffff;
    }

    .song-producer {
      font-size: 12px;
      color: #818181;
    }

    .song-favorite {
      position: absolute;
      top: 10px;
      right: 10px;
      z-index: 3;
    }
    
  }
}
