.category-page {
  h1 {
    color: #ffffff;
    font-size: 50px;
    font-family: "Proxima Nova Lt";
    font-weight: 300;
    text-align: center;
    margin-top: 30px;
    margin-bottom: 5px;
  }

  @media screen and (max-width: 1440px) {
    h1 {
      margin-top: 0;
      font-size: 30px;
    }
  }

  .chevron-container-left,
  .chevron-container-right {
    img {
      display: block;
      margin-top: 100px;
      width: 30px;
      opacity: 0.7;
      cursor: pointer;
      &:hover {
        opacity: 1;
      }
    }
  }

  .chevron-container-left {
    img {
      float: right;
    }
  }

  .playlist-thumbnail {
    position: relative;
    width: calc(25% - 5px);
    display: block;
    float: left;
    margin-right: 5px;
    margin-bottom: 5px;
  }
}
